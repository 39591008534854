<template lang="pug">
.page
  | this is 404 page

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

const foo = ref('bar')
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
